import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { client } from '../../../dal/apollo';
import './PaymentPreferences.css';
import { useParams } from 'react-router';

const GET_STRIPE_INFO = gql`
  query GetMyStripeInfo($slug: String!) {
    GetMyStripeInfo(slug: $slug)
  }
`;

export default function PaymentPreferences({}): any {
  //get slug from react-router location query params
  const { slug } = useParams() as any;
  const {
    loading,
    data: businessStripeData,
    error,
  } = useQuery(GET_STRIPE_INFO, {
    client: client,
    variables: { slug: slug },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  let stripeReady = false;
  let stripeDataParsed;
  try {
    stripeDataParsed = JSON.parse(businessStripeData?.GetMyStripeInfo);
    if (
      stripeDataParsed?.charges_enabled &&
      stripeDataParsed?.details_submitted
    ) {
      stripeReady = true;
    }
  } catch (e) {
    console.log('error', e);
    return null;
  }

  return (
    <div className='content'>
      <h2 id='stripe-section'>Get Paid: Connect to Stripe</h2>
      <span className='flex-break mt3' />
      <div>
        {stripeReady ? (
          'Stripe is connected and ready. Charges are enabled.'
        ) : (
          <div>
            {stripeDataParsed?.details_submitted ? (
              <div>All info submitted, waiting for account approval.</div>
            ) : (
              <div>
                Please start or continue the Stripe connection process here:
                <br />
                <a
                  href={
                    process.env.REACT_APP_API_SERVER +
                    '/stripe/setup-connect-account?slug=' +
                    slug
                  }
                >
                  <img
                    id='stripe-logo'
                    alt='Stripe Connect Button'
                    src='/images/blue-on-light@3x.png'
                  />
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
