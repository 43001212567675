import React from 'react';
import './Facet.css';
interface FacetProps {
  facetName: string;
  facets: Array<{ name: string; count: number }>;
}

function Facet({ facetName, facets }: FacetProps) {
  return (
    <div className='FacetComponent'>
      <div className='facetName'>{facetName}</div>
      <div className='facets'>
        {facets.map((facet) => (
          <div className='facet' key={facet.name}>
            {facet}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Facet;
