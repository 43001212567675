import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ShoppingCartContext from '../../../context/ShoppingCartContext';
import './SearchResultsProductTile.css';
import { ReactComponent as SpiritIcon } from '../../../svg/icons/spirits-glass.svg';
import { ReactComponent as WineIcon } from '../../../svg/icons/wine-glass.svg';
import { ReactComponent as BeerIcon } from '../../../svg/icons/beer-glass.svg';
import { ReactComponent as CiderIcon } from '../../../svg/icons/cider-glass.svg';
import { ReactComponent as CocktailIcon } from '../../../svg/icons/cocktail.svg';
import { ReactComponent as HardSeltzerIcon } from '../../../svg/icons/hard-seltzer.svg';
import { ReactComponent as HardKombuchaIcon } from '../../../svg/icons/hard-kombucha.svg';
import Facet from '../Facet/';

export interface SearchResultsProductTileProps {
  product: any;
  isWholesaleBuyer: boolean;
  disabled: boolean;
  editMode: boolean;
  facets?: Array<string>;
}

export default function SearchResultsProductTile(
  props: SearchResultsProductTileProps
): JSX.Element {
  const { product, isWholesaleBuyer, disabled, editMode, facets } = props;
  const priceList =
    (isWholesaleBuyer ? product.wholesalePricing : product.retailPricing) ?? [];
  const sortedPrices = priceList.sort(
    (price1: any, price2: any) => price1.price_per_unit - price2.price_per_unit
  );
  const history = useHistory();
  const {
    addToCart,
    state: shoppingCart,
    decreaseQuantity,
  } = useContext(ShoppingCartContext);
  const quantityCurrentlyInCart = shoppingCart.quantityById[product.id];
  const bottleIcons = {
    Wine: <WineIcon />,
    Cider: <CiderIcon />,
    Beer: <BeerIcon />,
    Spirit: <SpiritIcon />,
    Cocktail: <CocktailIcon />,
    'Hard Kombucha': <HardKombuchaIcon />,
    'Hard Seltzer': <HardSeltzerIcon />,
  };

  let beverageType;
  let bottleImage;
  function getPriceForQuantity(productQuantity: any, priceList: any) {
    //Sort in reverse price order (for .find() function to find the first match)
    const sortedPrices = priceList.sort(
      (price1: any, price2: any) =>
        price2.minimum_unit_count_for_price -
        price1.minimum_unit_count_for_price
    );
    let firstMatchingPriceForQuantity = sortedPrices.find(
      (price: any) => productQuantity >= price.minimum_unit_count_for_price
    );
    return firstMatchingPriceForQuantity?.price_per_unit.toFixed(2);
  }
  const priceForQuantity = getPriceForQuantity(
    quantityCurrentlyInCart,
    priceList
  );
  try {
    beverageType = product['Beverage Type'][0];
    //console.log("BEVERAGE TYPE IS", beverageType)
    // const abv = product["ABV"]
    // const closureStyles = product["Closure Style"]

    bottleImage = getBottleImage(product, bottleIcons, beverageType);

    //const nextPriceBreakQuantity = getNextPriceBreakQuantity(searchQuantity, priceList);

    if (beverageType === 'Beer + Cider') {
      beverageType = 'beer-and-cider';
    }
  } catch (error) {
    console.log(error);
    return <div></div>;
  }
  return (
    <>
      <div
        className={`product-tile ${beverageType.replace(' ', '-')}-type ${
          disabled ? 'disabled ' + disabled : ''
        }`}
      >
        <div className='product-tile-header'>
          <div className={'product-producer-name'}>
            <a
              className={'product-producer-name-link'}
              rel='noopener noreferrer'
              target='_blank'
              href={'/business/' + product.slug}
            >
              {product['Produced By'] || product.name}
            </a>
            <img
              className='new-tab-icon'
              src='/icons/new_tab.png'
              alt='Open in new tab.'
            />
          </div>
          <div className={'product-icon-rectangle'}>
            <div className='product-icon-svg'>
              {bottleIcons[beverageType as keyof typeof bottleIcons]}
            </div>
            <div className={'product-icon-title'}> {beverageType}</div>
          </div>
        </div>
        <div className='product-tile-below-header'>
          {product.short_description}
          <div className='product-image-section'>
            {/* <div className='product-abv'> {abv}% abv </div> */}
            {bottleImage}
            {/* <div className='product-volume'> {volumeToDisplay} ml</div> */}
          </div>
          {/* ${priceForQuantity}/ea <br /> */}
          {/* {nextPriceBreakQuantity ? `(shopping for ${searchQuantity} unit(s), next price break at ${nextPriceBreakQuantity} units.)` : "(this is the best price)"} */}
          <div></div>
          <div className='product-pricing'>
            {sortedPrices.map((price: any, index: any) => {
              return (
                <div key={index}>
                  {price?.minimum_unit_count_for_price} @ $
                  {price?.price_per_unit?.toFixed(2)}/ea <br />
                </div>
              );
              //  }
            })}
          </div>
          <div className='FacetComponent-container'>
            {facets?.map((facet, index) => {
              if (product[facet]) {
                return <Facet facetName={facet} facets={product[facet]} />;
              }
            })}
          </div>
          <div className='bottom-row'>
            <div className='pricing-box'>
              {/* <table>
              <th>
                <td>Quantity</td>
                <td>Price</td>
              </th>
              {sortedPrices.map((price, index) => {
                return (
                  <tr key={index}>
                    <td>{price.minimum_unit_count_for_price}</td>
                    <td>${price.price_per_unit.toFixed(2)}</td>
                  </tr>
                );
              })}
            </table> */}
              <table>
                <tbody>
                  <tr>
                    <td>price range:</td>
                    <td>
                      {' '}
                      ${sortedPrices[0]?.price_per_unit?.toFixed(2)} - $
                      {sortedPrices[
                        sortedPrices.length - 1
                      ]?.price_per_unit?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>quantites:</td>
                    <td>
                      {sortedPrices[0]?.minimum_unit_count_for_price} -{' '}
                      {
                        sortedPrices[sortedPrices.length - 1]
                          ?.minimum_unit_count_for_price
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*Add/Remove Product to Cart Section */}
            {editMode ? (
              <button
                className='edit-product-button'
                onClick={() => {
                  history.push(`./products/edit/${product.id}`);
                }}
              >
                Edit
              </button>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                {sortedPrices.length ? (
                  <div className='add-remove-product-to-cart'>
                    {shoppingCart.quantityById[product.id] === undefined ? (
                      <div
                        className={'add-to-cart'}
                        onClick={() => {
                          addToCart({
                            id: product.id,
                            product: product,
                            isUnique: false,
                          });
                        }}
                      >
                        Add to Cart
                      </div>
                    ) : (
                      ''
                    )}
                    {shoppingCart.quantityById[product.id] > 0 ? (
                      <>
                        <div style={{ textAlign: 'right' }}>
                          ${priceForQuantity} ea
                        </div>
                        <div className={'product-quantity-buttons'}>
                          <div
                            onClick={() => decreaseQuantity({ id: product.id })}
                          >
                            -
                          </div>
                          {quantityCurrentlyInCart}
                          <div
                            onClick={() => {
                              addToCart({
                                id: product.id,
                                product: product,
                                isUnique: false,
                              });
                            }}
                          >
                            +
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function getBottleImage(
  product: {
    [x: string]: any;
    producedBy: string;
    productType: string;
    bottleImageUrl: string | undefined;
  },
  bottleIcons: {
    [x: string]: any;
    Wine?: React.JSX.Element;
    Cider?: React.JSX.Element;
    Beer?: React.JSX.Element;
    Spirit?: React.JSX.Element;
    Cocktail?: React.JSX.Element;
    'Hard Kombucha'?: React.JSX.Element;
    'Hard Seltzer'?: React.JSX.Element;
  },
  beverageType: string | number
) {
  let bottleImage;
  if (product['bottleImageUrl']) {
    bottleImage = (
      <img
        className={'product-image'}
        alt={
          'product-image-for-' + product.producedBy + '-' + product.productType
        }
        src={product.bottleImageUrl}
      />
    );
  } else {
    bottleImage = bottleIcons[beverageType];
  }
  return bottleImage;
}

// export default withRouter(SearchResultsProductTile);

// let lineItemTotal;
// if (!isWholesaleBueyer) {
//   for (let i = 0; i < sortedPrices.length && quantityCurrentlyInCart >= sortedPrices[i].minimum_unit_count_for_price; i++) {
//     lineItemTotal = quantityCurrentlyInCart * sortedPrices[i].price_per_unit
//   }
// }

// function getNextPriceBreakQuantity(currentQuantity, priceList) {
//   //Sort in reverse price order (for .find() function to find the first match)
//   const sortedPrices = priceList.sort((price1, price2) => price2.minimum_unit_count_for_price - price1.minimum_unit_count_for_price)
//   let firstMatchingPriceForQuantity = sortedPrices.find(price => currentQuantity < price.minimum_unit_count_for_price);
//   return firstMatchingPriceForQuantity?.minimum_unit_count_for_price;
// }
