import React, { useEffect } from 'react';
import './Banner.css';

export enum BannerType {
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
  Error = 'error',
}

interface BannerProps {
  type: BannerType;
  lines: string[];
  bannerId: number;
  dismissible: boolean;
  link?: {
    title: string;
    url: string;
  };
}

function Banner() {
  const [banners, setBanners] = React.useState<BannerProps[]>([]);
  const [visibleIndex, setVisibleIndex] = React.useState(0);

  useEffect(() => {
    // Use fetch, and call our API to see if we have any banner info to display
    const bannerEndpoint = `${process.env.REACT_APP_API_SERVER}/banners`;
    // Include my auth headers in this fetch call
    fetch(bannerEndpoint, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        // Set title and subtitle based on the response
        setBanners(data);
      })
      .catch((err) => {
        console.log('ERROR:' + err);
      });
  }, []);

  const dismissBanner = (index: number) => {
    setBanners((prevBanners) => prevBanners.filter((_, i) => i !== index));
  };

  if (banners.length === 0) return null;
  return (
    <>
      {banners.map((banner, index) => (
        <div
          key={index}
          className={`banner ${banner.type}`}
          hidden={visibleIndex !== index}
        >
          <div className='icon'> </div>
          <div className='banner-content'>
            {banner.lines.map((line, i) => (
              <p key={i}>{line}</p>
            ))}
            <a className='button' href={banner.link?.url}>
              {banner.link?.title}
            </a>
          </div>
          {banner.dismissible && (
            <>
              <button
                className='dismiss-button'
                onClick={() => dismissBanner(index)}
              >
                X
              </button>
            </>
          )}
        </div>
      ))}
    </>
  );
}

export default Banner;
